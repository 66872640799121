import { BulkAction, Dialog } from "@dashboard/types";
import { stringifyQs } from "@dashboard/utils/urls";
import urlJoin from "url-join";

export const lockSection = "/locks/";
export const lockListPath = lockSection;

export const lockPath = (id: string) => urlJoin(lockSection, id);

export type LockUrlDialog = "reset";
export type LockUrlQueryParams = BulkAction & Dialog<LockUrlDialog>;

export const lockUrl = (id: string, params?: LockUrlQueryParams) =>
  lockPath(encodeURIComponent(id)) + "?" + stringifyQs(params);

export const lockCsvUploadURL = urlJoin(lockSection, "csv-upload");
